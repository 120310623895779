<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>
        Działaj na rzecz bezpieczeństwa ukraińskich dzieci z Fundacją Happy Kids
      </h1>
      <p>
        Dzięki Tobie najmłodsi poszkodowani działaniami zbrojnymi w Ukrainie
        mogą otrzymać opiekę w:
      </p>
      <ul>
        <li>Rodzinnych Domach Dziecka Fundacji Happy Kids</li>
        <li>rodzinach zastępczych</li>
        <li>
          domach dziecka na terenie Łodzi oraz innych samorządów z całej Polski.
        </li>
      </ul>
      <p>Przekazane darowizny zostaną przeznaczone na:</p>
      <ul>
        <li>transport</li>
        <li>wyżywienie</li>
        <li>środki czystości</li>
        <li>odzież</li>
        <li>pomoc medyczną</li>
        <li>terapię psychologiczną</li>
        <li>opiekę prawną.</li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji Happy Kids</strong>
      </p>
      <img :src="require('@/assets/img/logo-fhks.png')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div>
        <img :src="require('@/assets/img/logo-fhks.png')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>
        Działaj na rzecz bezpieczeństwa ukraińskich dzieci z Fundacją Happy Kids
      </h1>
      <p>
        Dzięki Tobie najmłodsi poszkodowani działaniami zbrojnymi w Ukrainie
        mogą otrzymać opiekę w:
      </p>
      <ul>
        <li>Rodzinnych Domach Dziecka Fundacji Happy Kids</li>
        <li>rodzinach zastępczych</li>
        <li>
          domach dziecka na terenie Łodzi oraz innych samorządów z całej Polski.
        </li>
      </ul>
      <p>Przekazane darowizny zostaną przeznaczone na:</p>
      <ul>
        <li>transport</li>
        <li>wyżywienie</li>
        <li>środki czystości</li>
        <li>odzież</li>
        <li>pomoc medyczną</li>
        <li>terapię psychologiczną</li>
        <li>opiekę prawną</li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto
        <strong>Fundacji Happy Kids</strong>
      </p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
